body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{font-family: 'Roboto', sans-serif;}

/* Calendar Datepicker */
.calendar-from-date {
  position: absolute;
  z-index: 99;
  height: 20px;
  right: 6px;
  background: white;
}
.ant-modal-close-x {
  display: none !important;
}
/* BULK LOAD */
.download-file {
  padding: 0 !important;
}
.download-file a {
    color: white;
    display: block;
    height: 35px;
    padding: 10px;
}

.download-file a:hover {
    color: white;
    display: block;
    height: 35px;
    padding: 10px;
}
.aplicar-bulkLoad-filters {
  width: 100% !important;
  margin: auto !important;
}
.wrap_filters {
  margin-right: -30px;
}
.msg-result-not-valid, .msg-result-dont-upload {
  display: block;
  width: 100%;
  float: right;
  text-align: center;
  color: red;
}
.msg-result-upload {
  display: block;
  width: 100%;
  float: right;
  text-align: center;
  color: green;
}
.strgTxt {
  font-weight: bolder;
  margin-top: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.right-side {
  right: 0;
  position: absolute;
  margin: 0;
  font-size: 13px;
}

.mid-container {
  display: inline-flex;
}
#last-pan {
  width: 85%;
  float: right
}
.last-pan {
  margin-left: 15px;
}
.first-td {
  min-width: 200px;
}
h1.text-center {
  text-align: center;
}
h1.text-center-whitelist {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-top: 150px;
  margin-left: 50px;
  margin-right: 50px;

}
.img-merchants-brands {
  width: 28px;
  display: inline-flex;
  margin: 0 5px;
}
img.brand-query {
  width: 25px;
  display: inline-flex;
  margin-left: 5px;
}
.left-arrow-users {
  color: white;
  font-size: 1.5em;
}
td.amount {
  text-align: right !Important;
}
table#merchants-table {
  /* width: auto !important; */
}
.merchants-light {
  background: none !important;
}
table#merchants-table tbody tr {
  background-color: #F4F4F4 !important;
}
td.brand {
  width: 190px;
  min-width: 200px;
}

img.user-img-profile {
  margin-top: 0px;
  border-radius: 50%;
}
.hero__header-change-picture-acquirer .media {
  max-width: 100%;
  position: relative;
  height: 130px;
  top: 0;
  text-align: center;
  padding: 0px 200px
}

@media only screen and (max-width: 768px) {
  .hero__header-change-picture-acquirer .media {
    /* float: left !important; */
    padding: 0px !important;
  }
  
}

.change-picture-acquirer .home__container {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 15px 0;
}
.hero__header-change-picture-acquirer img {
  width: 45px;
}
label.label-img {
  width: 50px !important;
    margin: 0 0 0 15px !important;
}
.lbl-txt-img {
  width: 100% !important;
  max-width: 190px !important;
    margin: 0 5px !important;
}
.ant-modal {
  max-width: 840px !important;
}
@media only screen and (max-width: 748px) {
  .ant-modal {
    max-width: calc(100vw - 16px) !important
  }
}
.userAvatarForm.lbl{
  display: inline-flex;
  align-items: center;
}
.hero__header-change-picture-acquirer input {
  opacity: 0;
    position: absolute;
    width: 0;
}
.close-modal-acq-pic {
  position: absolute;
  margin: -10px 15px;
  cursor: pointer;
  right: 0;
  z-index: 99;
}
@media only screen and (min-width: 992px) {
  .change-picture-acquirer li.cardAdq__item a {
    max-width: 360px !important;
    margin: 1rem auto;
  }
}
img.user-img-app{
  border-radius: 50%;
    margin-top: 0;
}
.navHeaderMenu__title {
  padding-top: 10%;
}
.reesent {
  color: #0083ff;
}

.brand-table {
  padding: 14px;
  height: auto;
  width: 65px;
}

.brand-table-visa {
  padding: 14px;
  height: 45px;
  width: 80px;
}

.brand-table-amex {
  padding: 14px;
  height: 60px;
  width: 60px;
}
